import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "loading"
    }}>{`Loading`}</h2>
    <ComponentCode name="Loading" component="loading" variation="loading" hasReactVersion="loading--default" hasVueVersion="loading--default" hasAngularVersion="?path=/story/loading--basic&knob-With overlay=true&knob-Size of the loading circle=normal" codepen="eoZzvV" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "loading-without-overlay"
    }}>{`Loading without overlay`}</h2>
    <ComponentCode name="Loading without overlay" component="loading" variation="loading--without-overlay" hasReactVersion="loading--default" hasVueVersion="loading--default&knob-overlay=false" hasAngularVersion="?path=/story/loading--basic" codepen="ROaRgb" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "loading-small"
    }}>{`Loading small`}</h2>
    <ComponentCode name="Loading small" component="loading" variation="loading--small" hasReactVersion="loading--default" hasVueVersion="loading--default&knob-small=true" hasAngularVersion="?path=/story/loading--basic&knob-With overlay=&knob-Size of the loading circle=sm" codepen="dLMXRE" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="loading" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      